import React from 'react';
import './App.css';

import { Progress, Slider, Button } from 'antd';
import { DeleteOutlined, PlusOutlined, PropertySafetyFilled } from '@ant-design/icons';

const COLORS = [
  '#f5222d',
  "#fa8c16",
  "#fadb14",
  "#a0d911",
  "#13c2c2",
  "#1890ff",
  "#722ed1",
  "#eb2f96"
];

function App() {


  const [activeSlider, setActiveSlider] = React.useState(null);

  const [sliders, setSliders] = React.useState([2, 2, 2]);

  const [rounds, setRounds] = React.useState(0);

  const [percentComplete, setPercentComplete] = React.useState(0);

  const [countdown, setCountdown] = React.useState(null);

  const addSlider = () => setSliders(prev => prev.concat(1));

  React.useEffect(() => {
    if(activeSlider === null) return;
    
    setPercentComplete(0);

    let nextCount = sliders[activeSlider] * 1000;

    let elapsed = 0
    const int = setInterval(() => {
      elapsed += 50;
      if((elapsed % 1000) === 0){
        console.log('make the tic sound!');
      }
      let perc = (elapsed / nextCount) * 100;
      setPercentComplete(perc);
      // console.log(elapsed);
      if(elapsed === nextCount){
        clearInterval(int);
        setPercentComplete(0);
        if(activeSlider === sliders.length - 1){
          setActiveSlider(0);
          setRounds(prev => prev+=1);
        } else {
          setActiveSlider(activeSlider + 1);
        }
        
        elapsed = 0;
        return;
      }
    }, 50);

    return () => clearInterval(int);

  }, [activeSlider, sliders]);

  const startTempo = () => {
    setActiveSlider(null);
    setPercentComplete(0);
    setRounds(0);
    setCountdown(5);

    const to = setTimeout(() => {
      setActiveSlider(0);
    }, 5000);

    return () => {
      clearTimeout(to);
    }
  }

  return (
    <div className="App">
      <main>
        <h2>Rounds: {rounds}</h2>
        <Progress className='progress' strokeColor={COLORS[activeSlider || 0]} strokeWidth={12} type='circle' percent={percentComplete} format={() => null} />
        <Button type='primary' size='large' onClick={startTempo}>Start</Button>
        {
          countdown && <Timer seconds={countdown} onDismiss={() => setCountdown(null)} />
        }
      </main>
      <footer>
        <div className='controls'>
          {
            sliders.map((slider, index) => {
              return <div key={index} className='slider-container'>
                <span style={{background: COLORS[index], padding: '2px 6px', color: "#fff", borderRadius: 8}}>{slider} sec.</span>
                <Slider 
                  className='slider'
                  value={slider}
                  trackStyle={{backgroundColor: COLORS[index]}} 
                  handleStyle={{borderColor: COLORS[index]}}
                  min={1} 
                  max={10} 
                  step={1} 
                  onChange={(val) => setSliders(prev => {
                  return prev.map((el, x) => {
                    if(x === index) return val;
                    return el;
                  })
                })} />
                <Button size='small' type="dashed" shape="circle" icon={<DeleteOutlined />} onClick={() => setSliders(prev => {
                  return prev.filter((el, x) => x !== index)
                })} />
              </div>
            })
          }
          <Button 
            size='large' 
            type="primary"
            shape="circle"
            icon={<PlusOutlined />} 
            onClick={addSlider} disabled={sliders.length === 8} />
        </div>
      </footer>
    </div>
  );
}

export default App;


const Timer = ({ seconds, onDismiss }) => {
  // initialize timeLeft with the seconds prop
  const [timeLeft, setTimeLeft] = React.useState(seconds);

  React.useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) {
      return onDismiss();
    };

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  return (
    <div>
      <h2>Starting in {timeLeft}...</h2>
    </div>
  );
};
